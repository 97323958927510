// API地址
// const Base_Url = process.env.NODE_ENV == 'production' ? 'https://platform.gohipet.com/' : 'http://gohitest.mynatapp.cc/';
const Base_Url = 'https://platform.gohipet.com/';
// const Base_Url = 'http://gohitest.mynatapp.cc/';

function configWXShare() {
    let shareParamData = {}
    let url = location.href.split('#')[0];
    $.ajax({
        url: `${Base_Url}v2/wechat/common`,
        type: 'get',
        data: { url },
        success: (res) => {
            wx.config(res.Data);
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({
                    title: 'Gohi宠物门店收银新零售', // 分享标题
                    desc: '快捷、智能、贴心', // 分享描述
                    link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'https://gohi.oss-cn-zhangjiakou.aliyuncs.com/assets/favicon.png', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateTimelineShareData({
                    title: 'Gohi宠物门店收银新零售-快捷、智能、贴心', // 分享标题
                    link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'https://gohi.oss-cn-zhangjiakou.aliyuncs.com/assets/favicon.png', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.onMenuShareWeibo({
                    title: 'Gohi宠物门店收银新零售', // 分享标题
                    desc: '快捷、智能、贴心', // 分享描述
                    link: url, // 分享链接
                    imgUrl: 'https://gohi.oss-cn-zhangjiakou.aliyuncs.com/assets/favicon.png', // 分享图标
                    success: function () {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                    }
                });
            });
        },
        error: (err) => {
            console.log(err)
            // alert("网络请求错误")
        }
    })
}

function init() {
    var docEl = document.documentElement,
        isIOS = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        dpr = isIOS ? Math.min(window.devicePixelRatio, 3) : 1,
        dpr = window.top === window.self ? dpr : 1,
        dpr = 1,
        scale = 1 / dpr,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    docEl.dataset.dpr = dpr;
    var metaEl = document.createElement('meta');
    metaEl.name = 'viewport';
    metaEl.content = 'initial-scale=' + scale + ',maximum-scale=' + scale + ', minimum-scale=' + scale;
    docEl.firstElementChild.appendChild(metaEl);
    var recalc = function () {
        var width = docEl.clientWidth;
        if (width / dpr > 750) {
            width = 750 * dpr;
        }
        docEl.style.fontSize = 100 * (width / 750) + 'px';
    };
    recalc()
    if (!window.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
}



configWXShare();
init();

// 提示弹框
function showToast(text) {
    if ($('#alert-box').text()) {
        return
    }
    $('body').append(`<div id="alert-box" style="background:rgba(0, 0, 0, 0.6); color:#eee; font-size: 0.26rem; border-radius: 8px; padding: 0.16rem; display: inline-block; position: fixed; top: 40%; left: 50%; transform: translate(-50%, 0); ">${text}</div>`)
    var timer = setTimeout(() => {
        $('#alert-box').remove()
        clearTimeout(timer)
    }, 2000)
}

export default {
    toggleDownBox: (event) => {
        event.stopPropagation();
        $($(".level-1-list").children()[1]).children(".level-2-list").toggleClass("active")
    },
    toggleFooterDownBox: (event) => {
        event.stopPropagation();
        $($(".f-level-1-list").children()[1]).children(".f-level-2-list").toggleClass("active")
    },
    togglePageDownBox(event) {
        event.stopPropagation();
        $(".menu-list > .navigation").toggleClass("active")
        $(".nav-mask").toggleClass("active")
        if ($(".nav-mask").attr("class").indexOf("active") > -1) {
            $(".menu-icon").css({
                display: "none"
            })
            $(".close-icon").css({
                display: "block"
            })
            $("#nav-download-btn").css({
                display: "none"
            })
        } else {
            $(".menu-icon").css({
                display: "block"
            })
            $(".close-icon").css({
                display: "none"
            })
            $("#nav-download-btn").css({
                display: "block"
            })
        }
    },
    closeAllMask: (event) => {
        event.stopPropagation();
        $(".menu-list > .navigation").removeClass("active")
        $(".nav-mask").removeClass("active")
        $(".menu-icon").css({
            display: "block"
        })
        $(".close-icon").css({
            display: "none"
        })
        $("#nav-download-btn").css({
            display: "block"
        })
    },

    //禁止页面滑动
    stopPageScroll() {
        var mo = function (e) { passive: false; };
        document.body.style.overflow = 'hidden';
        document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    //取消滚动限制
    allowPageScroll() {
        var mo = function (e) { passive: false };
        document.body.style.overflow = ''; //出现滚动条
        document.removeEventListener("touchmove", mo, false);
    },

    onLoad(selectorArr) {
        window.addEventListener("load", (e) => {
            var arr = selectorArr.length > 0 ? selectorArr : []
            arr.forEach(ele => {
                $(ele).addClass("active")
            });
        })
    },

    showToast,

    request(url, type, data) {
        let phoneReg = /^1\d{10}$/
        if (!phoneReg.test(data.Tel)) {
            showToast("请您输入正确的手机号")
            return
        }
        // return
        $.ajax({
            url: `${Base_Url}${url}`,
            type: type || 'post',
            data: JSON.stringify(data) || {},
            dataType: "json",
            async: true,
            success: (res) => {
                window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.jingtanhao.gohipet&g_f=undefined";
            },
            error: (err) => {
                console.log(err)
                alert("网络请求错误")
            }
        })
    }
}