/*
 * @Descripttion: 
 * @version: 
 * @Author: MaDi
 * @Date: 2021-12-02 16:56:53
 * @LastEditors: MaDi
 * @LastEditTime: 2022-01-06 12:06:52
 */
import '../css/m-index.less';
import '../css/m-common.less';
import commonObj from '../js/m-common'

// 导航菜单栏
$(".menu-icon").click((event) => {
    commonObj.togglePageDownBox(event)
    if($(".nav-mask").attr("class").indexOf("active") > -1) {
        commonObj.stopPageScroll()
    } else {
        commonObj.allowPageScroll()
    }
})
$(".close-icon").click((event) => {
    commonObj.closeAllMask(event)
    commonObj.allowPageScroll()
})
$(".nav-mask").click((event) => {
    commonObj.closeAllMask(event)
    commonObj.allowPageScroll()
})
// 顶部导航栏菜单二级分类展示切换
$($(".f-level-1-list").children()[1]).click((event) => {
    commonObj.toggleFooterDownBox(event)
    $(".f-level-1-list .right-arrow").toggleClass("open")
})
// 底部导航栏菜单二级分类展示切换
$($(".level-1-list").children()[1]).click((event) => {
    commonObj.toggleDownBox(event)
    $($(".level-1-item")[1]).toggleClass("open")
})

commonObj.onLoad(["#home", "#f-home a", "#f-home .after-line"])

// 跳转下一屏
$(".bottom-next-box").on("click", () => {
    // ie
    $('body').animate({ scrollTop: document.documentElement.clientHeight - 50}, 500);
    // 非ie
    $('html').animate({ scrollTop: document.documentElement.clientHeight - 50 }, 500);
})

// 客服咨询框放大
$(".floating-code-box").click(() => {
    $(".floating-code-box .code-img").toggleClass("active")
    if($(".floating-code-box .code-img").attr("class").indexOf("active") > -1) {
        commonObj.stopPageScroll()
    } else {
        commonObj.allowPageScroll()
    }
})

// 店铺/宠物二维码放大
$(".code-img-box._shop").click((e) => {
    e.stopPropagation()
    $(".code-img-box._shop .code-img").toggleClass("active")
    $(".code-img-box._pet .code-img").removeClass("active")
    
})
$(".code-img-box._pet").click((e) => {
    e.stopPropagation()
    $(".code-img-box._pet .code-img").toggleClass("active")
    $(".code-img-box._shop .code-img").removeClass("active")
})
$(document).click((e) => {
    $(".code-img-box._pet .code-img").removeClass("active")
    $(".code-img-box._shop .code-img").removeClass("active")
})

// 请求接口存储用户号码
function recordNumber(selectorArr) {
    selectorArr.forEach(ele => {
        $(ele[0]).on("click", (e) => {
            e.stopPropagation()
            commonObj.request(
                'common/operation/apply',
                'post',
                {
                    mobile: $(ele[1]).val(),
                    type: "1",
                }
            )
        })
    });
}
recordNumber([["#page-rgt-btn", "#page-rgt-inp"],["#footer-rgt-btn", "#footer-rgt-inp"]])


